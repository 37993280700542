import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { Button, Events, Link, scrollSpy } from "react-scroll";
import { ConfiguredLink, getUrlWithAnchor } from "../../../utils/linking.util";
import { LocalizedContent, LocalizedLabel, WidgetOptions } from "./";
import { NavItem, NavLink } from "reactstrap";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { SmartLink } from "../../../components/SmartLink";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { pageLink } from "../../../routing";

interface InlinePageLinkProps {
    options: WidgetOptions;
    className?: string;
    context: CMSProvidedProperties;
    link: ConfiguredLink;
}

interface InlinePageLinkState {
    href: string[] | "";
    pageToBeNavigated: string;
}

export class InlinePageLink extends React.PureComponent<InlinePageLinkProps, InlinePageLinkState> {
    constructor(props: InlinePageLinkProps) {
        super(props);
        this.state = {
            href: [],
            pageToBeNavigated: "",
        };
    }

    public componentDidMount() {
        // eslint-disable-next-line
        Events.scrollEvent.register("begin", () => {});

        // eslint-disable-next-line
        Events.scrollEvent.register("end", () => {});
        scrollSpy.update();
        const { context, options } = this.props;
        const { currentLocale, site } = context;
        if (options.useScrollTop) {
            const scrollTop = document.getElementsByClassName("go-to-top").item(0);
            window.addEventListener("scroll", () => {
                if (scrollTop) {
                    if (window.pageYOffset > 500) {
                        scrollTop.classList.add("to-top-visible");
                    } else {
                        scrollTop.classList.remove("to-top-visible");
                    }
                }
            });
        }

        const localizedContent: LocalizedContent | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedContent });

        if (localizedContent?.pageIdLocalized && localizedContent.siteIdLocalized) {
            const { pageIdLocalized, siteIdLocalized } = localizedContent;
            context.cmsApi.siteApi.findById({ id: siteIdLocalized, projection: { sitemap: 0 } }).then(async (site) => {
                if (site && pageIdLocalized) {
                    const url = (await pageLink({ site, pageId: pageIdLocalized, locale: currentLocale, context })) || "";
                    this.setState({ pageToBeNavigated: url });
                }
            });
        }

        const stack = JSON.parse(localStorage.getItem("pageNavigation") || "[]");
        const valueToBepushed = stack && stack[stack.length - 1] !== window.location.href ? window.location.href : "";
        if (valueToBepushed !== "" && stack[stack.length - 1] !== valueToBepushed && !valueToBepushed.includes("webmanager")) {
            if (stack.length > 0 && !(valueToBepushed.split("?")[0] === stack[stack.length - 1])) {
                stack.push(valueToBepushed);
            } else if (stack.length === 0) {
                stack.push(valueToBepushed);
            }
        }
        localStorage.setItem("pageNavigation", JSON.stringify(stack));
    }

    public componentWillUnmount() {
        Events.scrollEvent.remove("begin");
        Events.scrollEvent.remove("end");
        window.removeEventListener("scroll", () => 0);
    }

    // eslint-disable-next-line max-lines-per-function
    public render(): JSX.Element | null {
        const {
            className,
            options,
            context: { currentLocale, site },
            link,
        } = this.props;
        const activeClass = "active";

        const localizedLabel: LocalizedLabel | null = getLocalizedContent({ site, currentLocale, localizedContent: options.localizedLabel });
        const label = localizedLabel?.label && localizedLabel.label.trim().length ? localizedLabel?.label : link.anchor;

        const isAnchorLink = !link.url && link.anchor;
        const anchorLink = link.anchor?.toLocaleLowerCase();

        if (options.backButtonEnabled) {
            return (
                <NavItem className={"inline-nav-menu"}>
                    <NavLink tag={SmartLink} href={this.getPreviousLink()} className="nav-page__back-button">
                        <FontAwesome name="angle-left" /> {label}
                    </NavLink>
                </NavItem>
            );
        } else if (options.useScrollTop) {
            return (
                <Link
                    to={link.anchor || ""}
                    spy={true}
                    className={className || "go-to-top"}
                    activeClass={activeClass}
                    smooth={true}
                    offset={-100}
                    duration={1700}
                    delay={300}
                    isDynamic={true}
                    onSetActive={this.handleSetActive}
                >
                    <FontAwesome name="caret-up" />
                </Link>
            );
        } else if (options.useAsButton) {
            if (isAnchorLink && anchorLink) {
                return (
                    <Button
                        to={anchorLink}
                        spy={true}
                        className={`button button--secondary button--l ${className}`}
                        activeClass={activeClass}
                        smooth={true}
                        offset={-100}
                        duration={1700}
                        delay={300}
                        isDynamic={true}
                        onSetActive={this.handleSetActive}
                        type="submit"
                        value={label}
                    />
                );
            }

            return (
                <NavItem>
                    <NavLink tag={SmartLink} href={getUrlWithAnchor(link) || undefined} target={link.target} rel={link.rel} className={`${className} button button--secondary button--l`}>
                        {label}
                    </NavLink>
                </NavItem>
            );
        } else if (isAnchorLink && anchorLink) {
            return (
                <NavItem className={"inline-nav-menu inline-nav-menu-item"}>
                    <Link
                        to={anchorLink}
                        spy={true}
                        className={className}
                        activeClass={activeClass}
                        smooth={true}
                        offset={-100}
                        duration={1700}
                        delay={300}
                        isDynamic={true}
                        onSetActive={this.handleSetActive}
                    >
                        {label}
                    </Link>
                </NavItem>
            );
        }
        return (
            <NavItem className={classNames("inline-nav-menu", { ["menu-page-link"]: !link.isExternalLink, ["menu-external-link"]: link.isExternalLink })}>
                <NavLink tag={SmartLink} href={getUrlWithAnchor(link) || undefined} target={link.target} rel={link.rel}>
                    {label}
                </NavLink>
            </NavItem>
        );
    }

    // when user clicks on back button
    private getPreviousLink = () => {
        if (typeof localStorage !== "undefined") {
            const allLoadedUrls = JSON.parse(localStorage.getItem("pageNavigation") || "[]");
            let updatedStorage = [];
            if (allLoadedUrls.length) {
                allLoadedUrls.pop();
                updatedStorage = allLoadedUrls;
            }
            if (allLoadedUrls.length) {
                localStorage.setItem("pageNavigation", JSON.stringify(allLoadedUrls));
            }
            return updatedStorage.length ? updatedStorage[updatedStorage.length - 1] : "";
        }
        return "";
    };

    // eslint-disable-next-line
    private handleSetActive = () => {};
}
