import * as React from "react";

import { FormSpec, localized, multiSelectStylePicker } from "../../../form-specs";
import { LinkingSpecOptions, linkingSpec } from "../../../inputSpecs/linkingSpec";
import { MenuWidgetSpec, Widget } from "../../";
import { getI18nLocaleObject, getI18nLocaleString } from "../../../i18n";
import { getLinkFromLinkingSpec, linkingSpecDefaultValues } from "../../../utils/linking.util";

import { CMSProvidedProperties } from "../../../containers/cmsProvider.types";
import { InlinePageLink } from "./InlinePageLink";
import { LocalizedContentBase } from "@maxxton/cms-api";
import { WidgetGroup } from "../../widget.enum";
import { findMultiSelectStyleClassNames } from "../../../themes";
import namespaceList from "../../../i18n/namespaceList";

export interface LocalizedLabel extends LocalizedContentBase {
    label: string;
}

export interface LocalizedContent extends LocalizedContentBase {
    useExtUrlLocalized: boolean;
    externalUrlLocalized: string;
    usePageLinkLocalized: boolean;
    siteIdLocalized: string;
    pageIdLocalized: string;
    noFollowLink: boolean;
}

export interface WidgetOptions {
    anchorLabel?: string;
    styleIds: any[];
    useAsButton: boolean;
    useScrollTop: boolean;
    backButtonEnabled: boolean;
    localizedLabel: LocalizedLabel[];
    localizedContent: LocalizedContent[];
    hidden: boolean;
    openNewTab: boolean;
    linking: LinkingSpecOptions;
}

const TARGETS = ["menu"];
const widgetOptionsForm: FormSpec<WidgetOptions> = {
    id: "inlinepagelink-widget-options",
    name: getI18nLocaleObject(namespaceList.pluginMenu, "inlinepageLinkOptions"),
    pluralName: getI18nLocaleObject(namespaceList.pluginMenu, "inlinepageLinkOptions"),
    properties: [
        {
            type: "statictabs",
            tabs: [
                {
                    name: getI18nLocaleObject(namespaceList.pluginMenu, "general"),
                    properties: [
                        [
                            localized({
                                label: getI18nLocaleObject(namespaceList.pluginMenu, "localizedLabel"),
                                variable: "localizedLabel",
                                tabContent: [
                                    {
                                        variable: "label",
                                        label: getI18nLocaleObject(namespaceList.pluginMenu, "label"),
                                        type: "text",
                                    },
                                ],
                                visible: (options: WidgetOptions) => !options.useScrollTop,
                            }),
                            {
                                label: getI18nLocaleObject(namespaceList.widgetDropdownMenu, "menuItemVisibility"),
                                variable: "hidden",
                                type: "checkbox",
                            },
                            {
                                variable: "backButtonEnabled",
                                label: getI18nLocaleObject(namespaceList.pluginMenu, "backButtonEnabled"),
                                default: false,
                                type: "checkbox",
                                visible: (options: WidgetOptions) => !options.useScrollTop,
                            },
                            {
                                type: "paragraph",
                                label: getI18nLocaleObject(namespaceList.admin, "descriptionScrollToTop"),
                                visible: (options: WidgetOptions) => options.useScrollTop,
                            },
                            {
                                variable: "useScrollTop",
                                label: getI18nLocaleObject(namespaceList.pluginMenu, "useScrollTop"),
                                default: false,
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.pluginMenu, "styling"),
                    properties: [
                        [
                            multiSelectStylePicker("styleIds", TARGETS),
                            {
                                variable: "useAsButton",
                                label: getI18nLocaleObject(namespaceList.pluginMenu, "useAsButton"),
                                type: "checkbox",
                            },
                        ],
                    ],
                },
                {
                    name: getI18nLocaleObject(namespaceList.admin, "linking"),
                    properties: [[linkingSpec<WidgetOptions, keyof WidgetOptions>({ variable: "linking" })]],
                    visible: (options: WidgetOptions) => !options.backButtonEnabled,
                },
            ],
        },
    ],
};

export const inlinePageLinkWidget: MenuWidgetSpec<WidgetOptions> = {
    id: "inlinepagelink",
    type: "menu",
    widgetGroup: WidgetGroup.OTHER,
    name: getI18nLocaleObject(namespaceList.pluginMenu, "inlinepageLink"),
    description: getI18nLocaleObject(namespaceList.pluginMenu, "inlinepageLinkDescription"),
    optionsForm: widgetOptionsForm,
    defaultOptions: (): WidgetOptions => ({
        anchorLabel: "",
        styleIds: [],
        useAsButton: false,
        useScrollTop: false,
        backButtonEnabled: false,
        localizedLabel: [],
        localizedContent: [],
        hidden: false,
        openNewTab: false,
        linking: linkingSpecDefaultValues,
    }),
    async instanceDescription({ widget }): Promise<string> {
        const { linking } = widget.options;
        const label = linking.anchorLink;
        if (!label) {
            return getI18nLocaleString(namespaceList.pluginMenu, "noPage");
        }
        return label;
    },
    async render(widget: Widget<WidgetOptions>, context: CMSProvidedProperties) {
        const styleClasses = findMultiSelectStyleClassNames(context.theme, TARGETS, widget.options.styleIds);
        const link = await getLinkFromLinkingSpec({ linkingSpecOptions: widget.options.linking, context });
        return <InlinePageLink key={widget._id} className={styleClasses} options={widget.options} context={context} link={link} />;
    },
};
